#clap-button {
  position: relative;
}

.clap-animation {
  transform: scale(1.3);
  transition: transform 0.3s ease-in-out;
}

.clap-animation::after {
  content: "👏";
  position: absolute;
  font-size: 2rem;
  top: -20px;
  right: -20px;
  opacity: 0;
  animation: pop-up 0.5s forwards;
}

@keyframes pop-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

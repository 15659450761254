#root {
  width: -webkit-fill-available;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0) inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0) inset !important;
}
